import { isUserAuthorized } from "components/AuthorizationWrapper";
import { rolesStore } from "store/Store";
import { AUTHORITIES } from "utils/enums";

const getMenuItems = ({ teamId }) => {
  return [
    {
      key: "rerun",
      label: "Rerun workflow now",
      disabled: false,
      visible: isUserAuthorized({
        teamId,
        allRequired: rolesStore.getActions([AUTHORITIES.WORKFLOW_SUBMIT]),
      }),
    },
    {
      key: "restore",
      label: "Restore files to queue",
      disabled: false,
      visible: isUserAuthorized({
        teamId,
        allRequired: rolesStore.getActions([AUTHORITIES.WORKFLOW_SUBMIT]),
      }),
    },
    {
      key: "view",
      label: "View workflow run history",
      disabled: false,
    },
    {
      key: "queue",
      label: "View workflow queue",
      disabled: false,
    },
  ];
};

export { getMenuItems };
