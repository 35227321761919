import { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { IconVerticalMenu, Text } from "@fundrecs/ui-library";
import { useTeamId } from "store/hooks/useTeamId";

import styles from "./VerticalMenu.module.scss";

const VerticalMenu = (props) => {
  const tippyRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const { menuItems, onItemClick, getMenuItemsFromRowData = false, disableMenu = false } = props || {};
  const teamId = useTeamId();

  /**
   * getMenuItemsFromRowData is used when the menu options is based off some value from the row
   * it ignores the menuItems value
   */
  useEffect(() => {
    const fetchMenuItems = async () => {
      if (!getMenuItemsFromRowData) {
        setMenuOptions(menuItems);
      } else {
        const { data } = props || {};
        const fetchedMenuItems = await getMenuItemsFromRowData({ teamId, data });

        setMenuOptions(fetchedMenuItems);
      }
    };

    fetchMenuItems();
  }, [getMenuItemsFromRowData, menuItems, teamId, props]);

  useEffect(() => {
    if (!menuOptions.some((v) => v.visible === true)) {
      setDisabled(true);
    } else {
      setDisabled(disableMenu);
    }
  }, [disableMenu, menuOptions]);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  /**
   * Render Menu based on menuItems from props
   * - disabled is set to false if value is not passed - it show the option but does not allow user to click
   * - visible is set to true if value is not passed - the option won't be displayed if is false - this can be used for access control
   */
  const menuContent = () => {
    const onClickHandler = (option, props) => {
      closeMenu();
      onItemClick(option, props);
    };

    return (
      <div className={[styles.menuContent, "menu-container"].join(" ")}>
        {menuOptions.length > 0 &&
          menuOptions.map(
            ({ key, label, disabled = false, visible = true }) =>
              visible && (
                <div key={key} onClick={disabled ? () => {} : () => onClickHandler(key, props)}>
                  <span href="#" className={["dropdown-item", styles.dropdownItem, disabled ? styles.cursorDefault : styles.cursorPointer].join(" ")}>
                    <Text size="sm" variant={disabled ? "muted" : "primary"} weight="regular" theme="light">
                      {label}
                    </Text>
                  </span>
                </div>
              )
          )}
      </div>
    );
  };

  const handleClick = (event) => {
    event.stopPropagation();
    isOpen ? closeMenu() : openMenu();
  };

  return (
    <Tippy
      ref={tippyRef}
      content={menuContent()}
      visible={isOpen}
      onClickOutside={closeMenu}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="left"
    >
      <div onClick={handleClick} className={[styles.cursorPointer, disabled ? styles.disabled : ""].join(" ")}>
        <IconVerticalMenu />
      </div>
    </Tippy>
  );
};

export { VerticalMenu };
