import { useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { TabsSection } from "@fundrecs/ui-library";
import { TABS } from "utils/enums";
import styles from "./Tabs.module.scss";

/**
 * Configuration for all tabs, including their display names, keys, and disabled status.
 */
const TAB_CONFIG = [
  { name: TABS.RESULTS.name, key: TABS.RESULTS.key, disabled: false },
  { name: TABS.QUEUE.name, key: TABS.QUEUE.key, disabled: false },
  { name: TABS.OVERVIEW.name, key: TABS.OVERVIEW.key, disabled: false },
  { name: TABS.AUDIT_LOG.name, key: TABS.AUDIT_LOG.key, disabled: true },
  { name: TABS.UPLOADED_FILES.name, key: TABS.UPLOADED_FILES.key, disabled: true },
];

/**
 * Tabs component
 *
 * @param {string} activeTabKey - The key of the currently active tab.
 * @param {function} setActiveTabKey - Function to set the active tab key.
 */
const Tabs = ({ activeTabKey, setActiveTabKey }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Filter out disabled tabs and memoize the result
  const enabledTabs = useMemo(() => TAB_CONFIG.filter((tab) => !tab.disabled), []);

  // Updates active tab and URL params
  const updateActiveTab = useCallback(
    (newTabKey) => {
      setActiveTabKey(newTabKey);
      searchParams.set("tab", newTabKey);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setActiveTabKey, setSearchParams]
  );

  useEffect(() => {
    const tabParam = searchParams.get("tab")?.toLowerCase();
    const defaultTabKey = enabledTabs[0]?.key;

    // if the tab query parameter is for a tab that doesn't exist use the first enabled tab
    if (!tabParam || !enabledTabs.some((tab) => tab.key === tabParam)) {
      defaultTabKey && updateActiveTab(defaultTabKey);
    } else {
      setActiveTabKey(tabParam);
    }
  }, [searchParams, enabledTabs, updateActiveTab, setActiveTabKey]);

  const handleTabClick = useCallback(
    (tab) => {
      if (!tab.disabled) {
        updateActiveTab(tab.key);
      }
    },
    [updateActiveTab]
  );

  return (
    <div className="pl-32 pr-32 ml-32">
      <TabsSection>
        <ul className="nav">
          {TAB_CONFIG.map((tab) => {
            const disabledClass = tab.disabled ? styles.disabled : "";
            const activeClass = activeTabKey === tab.key ? `tab-link-active ${styles.active}` : styles.inactive;

            const tabClassName = ["tab-link text-sm text-medium", disabledClass || activeClass].join(" ");

            return (
              <li key={tab.key} onClick={() => handleTabClick(tab)} className={tab.disabled ? styles.disabledTab : ""}>
                <span className={tabClassName}>{tab.name}</span>
              </li>
            );
          })}
        </ul>
      </TabsSection>
    </div>
  );
};

export { Tabs };
