import { useState, useEffect, createContext } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Heading, modalInstance, R, C, C4 } from "@fundrecs/ui-library";
import { VerticalMenu } from "../../../ag-grid/verticalMenu/VerticalMenu";
import { Table } from "../../../ag-grid/Ag-grid";
import { NoQueuedFiles } from "../../NoWorkflows";
import { MODAL_IDS } from "../../../../utils/workflows/enums";
import { RefreshControl } from "../../../reusable/RefreshControl/RefreshControl";
import { RunSelectedFilesButton } from "../buttons/RunSelectedFilesButton";
import { RemoveSelectedFilesButton } from "../buttons/RemoveSelectedFileButton";
import { RemovePendingFileModal } from "../modals/RemovePendingFile";
import { createBrowserDownload } from "../../../../utils/file";
import { MIME_TYPE, TABS } from "utils/enums";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { autoSizeAll } from "components/ag-grid/Events/autoSizeAll";

const QueueContext = createContext();

const QueueTab = observer(({ loading, getPendingFilesData, fileData, handleRemovePendingFiles, handleRunPendingFiles, activeTabKey }) => {
  const { workflowsStore } = useStore();
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const [verticalMenuRow, setVerticalMenuRow] = useState();
  const teamId = useTeamId();

  useEffect(() => {
    if (activeTabKey === TABS.QUEUE.key) {
      getPendingFilesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabKey, teamId]);

  const checkDisabled = () => {
    return selectedRowsCount === 0;
  };

  const queueContext = {
    teamId,
    gridApi,
    setGridApi,
    selectedRowsCount,
    setSelectedRowsCount,
    verticalMenuRow,
    setVerticalMenuRow,
  };

  return (
    <div className="pt-20 pr-0 pl-0">
      <QueueContext.Provider value={queueContext}>
        <R props="ml-0 mt-16 mb-8 mr-0">
          <C4 props="ml-0">
            <Heading variant="h5" element="span" align="left">
              Queued files ({fileData?.length ?? 0})
              <span className="pl-20" />
              <RefreshControl refreshing={loading} onClick={getPendingFilesData} />
            </Heading>
          </C4>
          <C props="d-flex justify-content-end">
            <RemovePendingFileModal modalId={MODAL_IDS.REMOVE_WORKFLOW_PENDING_FILE} handleRemovePendingFiles={handleRemovePendingFiles} />
            <RunSelectedFilesButton checkDisabled={checkDisabled()} handleRunPendingFiles={handleRunPendingFiles} />
            <span className="pl-20" />
            <RemoveSelectedFilesButton checkDisabled={checkDisabled()} />
          </C>
        </R>
        <div className="pt-24 ">
          <LatestPendingFiles
            fileData={fileData}
            teamId={teamId}
            workflowsStore={workflowsStore}
            setSelectedRowsCount={setSelectedRowsCount}
            gridApi={gridApi}
            setGridApi={setGridApi}
            setVerticalMenuRow={setVerticalMenuRow}
          />
        </div>
      </QueueContext.Provider>
    </div>
  );
});

const LatestPendingFiles = ({ fileData, workflowsStore, setSelectedRowsCount, gridApi, setGridApi, setVerticalMenuRow }) => {
  const downloadQueuedFile = async (event) => {
    const { fileUuid, fileName } = event;
    const fileDownloadData = await workflowsStore.getRunResultFileData(fileUuid);

    const fileExt = fileName.split(".").pop().toUpperCase();

    if (fileExt === "CSV") {
      createBrowserDownload(fileName, fileDownloadData, MIME_TYPE.CSV);
    } else if (fileExt === "XLSX") {
      createBrowserDownload(fileName, fileDownloadData, MIME_TYPE.XLSX);
    } else if (fileExt === "XML") {
      createBrowserDownload(fileName, fileDownloadData, MIME_TYPE.XML);
    } else {
      createBrowserDownload(fileName, fileDownloadData, MIME_TYPE.DEFAULT);
    }
  };

  const onItemClick = (option, props) => {
    const { data } = props || {};
    switch (option) {
      case "download":
        downloadQueuedFile(data);
        break;
      case "remove":
        setVerticalMenuRow(data);
        modalInstance(MODAL_IDS.REMOVE_WORKFLOW_PENDING_FILE).toggle();
        break;
      default:
        break;
    }
  };

  const onRowSelected = (params) => {
    if (params.api.getSelectedRows().length > 0) {
      setSelectedRowsCount(params.api.getSelectedRows().length);
    } else {
      setSelectedRowsCount(0);
    }
  };

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params.api);
    }
    autoSizeAll(true, params);
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: false,
    detailRowAutoHeight: true,
    suppressContextMenu: true,
    suppressRowClickSelection: true,
    onRowSelected: onRowSelected,
    onGridReady: onGridReady,
    onGridSizeChanged: onGridSizeChanged,
    noRowsOverlayComponent: NoQueuedFiles,
  };

  const menuItems = [
    {
      key: "remove",
      label: "Remove",
      disabled: false,
      visible: true,
    },
    {
      key: "download",
      label: "Download",
      disabled: false,
      visible: true,
    },
  ];

  const Time = (params) => {
    return params.value !== null ? moment(params.value).format("DD MMM YYYY, HH:mm") : "-";
  };

  const columns = [
    {
      headerName: "",
      field: "rowStartSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "left",
      width: 50,
      headerCheckboxSelection: true,
      suppressMenu: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => (!params.api.addRow ? true : false),
    },
    {
      headerName: "File name",
      suppressMenu: true,
      field: "fileName",
      rowDrag: false,
      wrapText: false,
      editable: true,
      flex: 2,
    },
    {
      headerName: "Template applied",
      resizable: true,
      suppressMenu: true,
      field: "appliedTemplateName",
      rowDrag: false,
      wrapText: false,
      flex: 2,
    },
    {
      headerName: "Date uploaded",
      resizable: true,
      suppressMenu: true,
      field: "createdDate",
      rowDrag: false,
      editable: false,
      wrapText: false,
      flex: 2,
      sort: "desc",
      valueFormatter: Time,
    },
    {
      headerName: "",
      field: "rowEndSettings",
      suppressMenu: true,
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: { menuItems, onItemClick },
    },
  ];

  return <Table columns={columns} rowData={fileData} agGridOptions={gridOptions} />;
};

export { QueueTab, QueueContext };
