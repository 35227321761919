import { makeAutoObservable, toJS } from "mobx";
import { uiStore } from "../Store";
import { RULE_COMMAND_TYPES, OUTPUT_FILE_NAMING_TYPES, INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT } from "utils/workflows/enums";

class Workflow {
  store = null;

  uuid = null;
  type = null;
  teamId = null;
  name = null;
  description = null;
  status = null;
  createdBy = null;
  createdAt = null;
  lastModifiedBy = null;
  lastModifiedAt = null;

  configuration = null;

  constructor(store, json) {
    makeAutoObservable(this, {
      /**
       * The properties below can't be modified by the user or the backend
       * after creation, so we can disable reactivity for them
       */
      store: false,
      uuid: false,
      type: false,
      teamId: false,
      createdBy: false,
      createdAt: false,
    });

    this.store = store;
    this.updateFromJson(json);
  }

  updateFromJson(json) {
    const { uuid, type, teamId, name, description, status, createdBy, createdAt, lastModifiedBy, lastModifiedAt, configuration } = json;

    this.uuid = uuid;
    this.type = type;
    this.teamId = teamId;
    this.name = name;
    this.description = description;
    this.status = status;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.lastModifiedBy = lastModifiedBy;
    this.lastModifiedAt = lastModifiedAt;
    this.configuration = configuration;
  }

  get asJsonPatch() {
    /**
     * Return only the mutable Workflow properties as required by a PATCH operation
     */
    return {
      name: this.name,
      description: this.description,
      configuration: toJS(this.configuration),
    };
  }

  async save() {
    return await this.store.api
      .updateWorkflow(this.teamId, this.uuid, this.asJsonPatch)
      .then(({ data }) => {
        this.updateFromJson(data);
      })
      .catch((error) => {
        uiStore.addNotification("error", `Workflow could not be updated. ${error}`);
        throw new Error(error);
      });
  }

  async publish() {
    return await this.store.api
      .publishWorkflow(this.teamId, this.uuid)
      .then(({ data, status }) => {
        if (status === 200 || 201) {
          this.updateFromJson(data);
          const { name } = data;
          uiStore.addNotification("success", `Workflow "${name}" successfully published.`);
          return { success: true };
        } else {
          const { name } = data;
          uiStore.addNotification("error", `Unable to publish Workflow "${name}"! Please try again.`);
          return { success: false };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Workflow could not be published. ${error}`);
        throw new Error(error);
      });
  }

  async saveWorkflowClonedTrigger() {
    return await this.store.api
      .updateWorkflow(this.teamId, this.uuid, this.asJsonPatch)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", "Trigger successfully cloned");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to clone trigger! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", "Unable to clone trigger! Please try again");
        throw new Error(error);
      });
  }

  async uploadFile(file, templateUuid) {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("templateUuid", templateUuid);

    return await this.store.api.triggerWorkflow(this.teamId, this.uuid, formData);
  }

  getCurrentWorkflowTriggers = () => {
    const { triggers = [] } = this.configuration;
    return triggers;
  };

  addNewCurrentWorkflowTrigger = (newTrigger) => {
    const triggers = this.getCurrentWorkflowTriggers();
    triggers.push(newTrigger);
  };

  updateTriggerName = (value, triggerIndex) => {
    const triggers = this.getCurrentWorkflowTriggers();
    triggers[triggerIndex]["triggerName"] = value;
  };

  getCurrentWorkflowReports = () => {
    const {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports = [] },
        },
      },
    } = this.configuration;

    return reports;
  };

  updateCurrentWorkflowReportOutputMapping = (reportIndex, outputMappingUuid) => {
    const reports = this.getCurrentWorkflowReports();
    reports[reportIndex]["outputMappingUuid"] = outputMappingUuid;
    reports[reportIndex]["commands"] = [];
    reports[reportIndex]["fileNaming"] = { type: OUTPUT_FILE_NAMING_TYPES.AUTO };
  };

  getCurrentWorkflowReportOutputMapping = (reportIndex) => {
    const reports = this.getCurrentWorkflowReports();

    return reports[reportIndex]["outputMappingUuid"];
  };

  addNewCurrentWorkflowReport = (newReport) => {
    const reports = this.getCurrentWorkflowReports();

    reports.push(newReport);
  };

  updateReportName = (value, reportIndex) => {
    const reports = this.getCurrentWorkflowReports();

    reports[reportIndex]["reportName"] = value;
  };

  getCurrentWorkflowTemplates = () => {
    const {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules = [] },
        },
      },
    } = this.configuration;

    return templateImportRules;
  };

  addNewCurrentWorkflowImportRule = (newImportRule) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();

    templateImportRules.push(newImportRule);
  };

  updateTemplateImportRuleName = (value, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();

    templateImportRules[importRuleIndex]["importRuleName"] = value;
  };

  updateTemplateImportRuleSourceType = (type, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();

    templateImportRules[importRuleIndex]["source"]["type"] = type;
  };

  addTemplateImportRule = (template, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();
    templateImportRules[importRuleIndex]["inclusionRules"].push(template);
  };

  getTemplateImportRules = (importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();

    return templateImportRules[importRuleIndex]["inclusionRules"];
  };

  removeTemplateImportRule = (uuid, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();
    const templates = templateImportRules[importRuleIndex]["inclusionRules"].filter((template) => template.templateUuid !== uuid);

    this.replaceTemplateImportRules(templates, importRuleIndex);
  };

  replaceTemplateImportRules = (inclusionRules, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();

    templateImportRules[importRuleIndex]["inclusionRules"] = inclusionRules;
  };

  changeTemplateImportRule = (newTemplateUuid, templateToChange, importRuleIndex) => {
    const templateImportRules = this.getCurrentWorkflowTemplates();
    const inclusionRules = templateImportRules[importRuleIndex]["inclusionRules"];

    const templates = inclusionRules?.map((template) => {
      if (template.templateUuid === templateToChange) {
        template.templateUuid = newTemplateUuid;
      }
      return template;
    });

    this.replaceTemplateImportRules(templates, importRuleIndex);
  };

  getCommands = (reportIndex) => {
    const reports = this.getCurrentWorkflowReports();
    return reports[reportIndex]["commands"];
  };

  setCommands = (reportIndex, commands) => {
    const reports = this.getCurrentWorkflowReports();

    reports[reportIndex]["commands"] = commands;
  };

  getCommandsExceptType = (reportIndex, commandType) => {
    return this.getCommands(reportIndex)?.filter((command) => command?.name !== commandType);
  };

  doesRuleExist = (reportIndex, ruleName) => {
    return this.getCommands(reportIndex)?.some((c) => c?.name === ruleName);
  };

  deleteCommand = (reportIndex, rule) => {
    this.setCommands(reportIndex, this.getCommandsExceptType(reportIndex, rule));
  };

  getColumnOrderNumberForGroupByCommand = (reportIndex) => {
    if (this.getCommands(reportIndex) === undefined) {
      return;
    }

    const existingRule = this.getCommands(reportIndex).filter((command) => command.name === RULE_COMMAND_TYPES.GroupByCommand);

    if (existingRule?.length > 0) {
      const [
        {
          params: { columnOrderNumber },
        },
      ] = existingRule;

      return columnOrderNumber;
    }
  };

  getIncludeFirstCellForGroupByCommand = (reportIndex) => {
    if (this.getCommands(reportIndex) === undefined) {
      return;
    }

    const existingRule = this.getCommands(reportIndex).filter((command) => command.name === RULE_COMMAND_TYPES.GroupByCommand);

    if (existingRule?.length > 0) {
      const [
        {
          params: { firstCell },
        },
      ] = existingRule;

      return firstCell;
    }
  };

  getColumnIndexArrayForIncludeColumnsCommand = (reportIndex) => {
    if (this.getCommands(reportIndex) === undefined) {
      return;
    }

    const existingRule = this.getCommands(reportIndex).filter((command) => command.name === RULE_COMMAND_TYPES.IncludeColumnsCommand);

    const [
      {
        params: { includedColumnIndexArray },
      },
    ] = existingRule || [{}];

    return includedColumnIndexArray;
  };

  updateFileNamingTypeForReport = (type, reportIndex) => {
    const reports = this.getCurrentWorkflowReports();

    reports[reportIndex]["fileNaming"]["type"] = type;
  };

  removeFileNameTags = (reportIndex) => {
    const reports = this.getCurrentWorkflowReports();

    if (this.isFirstCellEnabled(reportIndex)) {
      reports[reportIndex]["fileNaming"]["fileName"] = reports[reportIndex]["fileNaming"]["fileName"]?.filter((tag) => tag?.type === "firstCellOfColumn");
    } else {
      reports[reportIndex]["fileNaming"]["fileName"] = [];
    }
  };

  addFileNameTags = (reportIndex, tags) => {
    const reports = this.getCurrentWorkflowReports();

    const isEqual = (tag1, tag2) => tag1.type === tag2.type;
    const filteredArray = tags.filter((tag1) => !reports[reportIndex]["fileNaming"]["fileName"].some((tag2) => isEqual(tag1, tag2)));
    reports[reportIndex]["fileNaming"]["fileName"] = [...reports[reportIndex]["fileNaming"]["fileName"], ...filteredArray];
  };

  getExistingFirstCellTag = (reportIndex) => {
    const existingFileName = this.getFileNameTags(reportIndex);
    return existingFileName.filter((tag) => tag.type === "firstCellOfColumn");
  };

  getFileNameTags = (reportIndex) => {
    const reports = this.getCurrentWorkflowReports();
    const {
      fileNaming: { fileName = [] },
    } = reports[reportIndex];

    return fileName ?? [];
  };

  getFirstOutputCellSelectedValue = (reportIndex) => {
    if (this.getExistingFirstCellTag(reportIndex)?.length > 0) {
      const [outputColumn] = this.getExistingFirstCellTag(reportIndex);
      const { n } = outputColumn;
      return n;
    }
  };

  getColumnSplitByIndex = (reportIndex) => {
    const commands = this.getCommandsExceptType(reportIndex, RULE_COMMAND_TYPES.IncludeColumnsCommand);
    if (commands.length > 0) {
      const [
        {
          params: { index },
        },
      ] = commands;
      return index;
    }
  };

  getColumnsBadgeCount = (reportIndex) => {
    return this.getColumnIndexArrayForIncludeColumnsCommand(reportIndex)?.length - INCLUDE_COLUMNS_COMMAND_DISPLAY_COUNT;
  };

  addfirstCellOfColumnToFileNaming = (columnName, reportIndex, index) => {
    const reports = this.getCurrentWorkflowReports();
    let {
      fileNaming: { type, fileName = [] },
    } = reports[reportIndex];
    if (type === OUTPUT_FILE_NAMING_TYPES.AUTO) {
      //The we need to set it to custom
      this.updateFileNamingTypeForReport(OUTPUT_FILE_NAMING_TYPES.CUSTOM, reportIndex);
    }
    const firstCell = {
      n: index,
      type: "firstCellOfColumn",
      value: columnName,
    };
    //Remove existing file types for firstCellofColumn
    if (fileName !== null) {
      let fileNames = fileName.filter((fileType) => {
        return fileType.type !== "firstCellOfColumn";
      });
      fileNames.push(firstCell);
      reports[reportIndex]["fileNaming"]["fileName"] = fileNames;
    } else {
      reports[reportIndex]["fileNaming"]["fileName"] = [];
      reports[reportIndex]["fileNaming"]["fileName"].push(firstCell);
    }
  };

  isFirstCellEnabled = (reportIndex) => {
    const commands = this.getCommandsExceptType(reportIndex, RULE_COMMAND_TYPES.IncludeColumnsCommand);
    if (commands?.length > 0) {
      const [
        {
          params: { firstCell },
        },
      ] = commands;
      return firstCell;
    }
  };

  addRuleToCommands = (ruleObject) => {
    const {
      reportIndex,
      rule: { activeRule, params },
    } = ruleObject;

    if (this.getCommands(reportIndex) === undefined) {
      this.setCommands(reportIndex, []);
    }

    switch (activeRule) {
      case RULE_COMMAND_TYPES.GroupByCommand:
        {
          const { id, index, columnOrderNumber, columnName } = params?.groupByCommandColumn;
          const { value } = params?.includeFirstCell;

          const newCommand = {
            name: RULE_COMMAND_TYPES.GroupByCommand,
            params: { index: index, uuid: id, columnOrderNumber: columnOrderNumber, firstCell: value },
          };

          const commands = this.getCommandsExceptType(reportIndex, RULE_COMMAND_TYPES.GroupByCommand);
          commands.push(newCommand);
          this.setCommands(reportIndex, commands);

          if (value) {
            this.addfirstCellOfColumnToFileNaming(columnName, reportIndex, index);
          } else {
            //Reset Output Name to AUTO when includeFirstCell is false, also wipe existing tags
            this.updateFileNamingTypeForReport(OUTPUT_FILE_NAMING_TYPES.AUTO, reportIndex);
            this.removeFileNameTags(reportIndex);
          }
        }
        break;
      case RULE_COMMAND_TYPES.IncludeColumnsCommand:
        {
          const newCommand = {
            name: RULE_COMMAND_TYPES.IncludeColumnsCommand,
            params: params,
          };
          const commands = this.getCommandsExceptType(reportIndex, newCommand.name);
          // Include Columns Command has to be before Group By Command - add to start of array
          commands.unshift(newCommand);
          this.setCommands(reportIndex, commands);
        }
        break;
      default:
        break;
    }
  };
}

export { Workflow };
