import { useContext } from "react";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { QueueContext } from "../tabs/QueueTab";

const RemovePendingFileModal = ({ modalId, handleRemovePendingFiles }) => {
  const { teamId, gridApi, verticalMenuRow } = useContext(QueueContext);

  const handleConfirmation = () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length > 0 || verticalMenuRow) {
      const itemsToUpdate = selectedRows.length > 0 ? selectedRows : [verticalMenuRow];

      const queuedFiles = itemsToUpdate.map((item) => item.workflowQueuedFileId);
      handleRemovePendingFiles(teamId, queuedFiles);
    }
  };

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading={"Are you sure you want to remove the selected file(s) from queue?"}
      text="This action will affect what the workflow processes and transforms at its next scheduled run."
      cancel="Cancel"
      confirm="Remove"
      onConfirm={() => handleConfirmation()}
      onCancel={() => {
        gridApi.deselectAll();
      }}
    />
  );
};

export { RemovePendingFileModal };
