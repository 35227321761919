import { makeAutoObservable } from "mobx";

class WorkflowQueuedFile {
  store = null;

  fileUuid = null;
  fileName = null;
  workflowQueuedFileId = null;
  templatesAssigned = null;
  createdDate = null;

  constructor(store, teamId, json) {
    makeAutoObservable(this, {
      /**
       * The properties below can't be modified by the user or the backend
       * after creation, so we can disable reactivity for them
       */
      store: false,
      fileUuid: false,
      fileName: false,
      workflowQueuedFileId: false,
      teamId: false,
      templatesAssigned: false,
      createdDate: false,
    });

    this.store = store;
    this.teamId = teamId;

    this.updateFromJson(json);
  }

  updateFromJson(json) {
    const { fileUuid, fileName, workflowQueuedFileId, templatesAssigned, createdDate } = json;

    this.fileUuid = fileUuid;
    this.fileName = fileName;
    this.workflowQueuedFileId = workflowQueuedFileId;
    this.templatesAssigned = templatesAssigned;
    this.createdDate = createdDate;
  }
}

export { WorkflowQueuedFile };
