const AUTH_ROUTES = Object.freeze({
  LOGIN: "/login",
  LOGOUT: "/logout",
});

/**
 * This is a map of all Authority Strings in Fusion. This should be a mirror of
 * the authorities contained in the Action table in the main Fusion db
 */
const AUTHORITIES = Object.freeze({
  TEAM_CREATE: "TEAM_CREATE",
  TEAM_VIEW: "TEAM_VIEW",
  TEAM_EDIT: "TEAM_EDIT",
  TEAM_DELETE: "TEAM_DELETE",
  USER_CREATE: "USER_CREATE",
  USER_VIEW: "USER_VIEW",
  USER_EDIT: "USER_EDIT",
  USER_DELETE: "USER_DELETE",
  USER_CHANGE_ROLES: "USER_CHANGE_ROLES",
  WORKFLOW_CREATE: "WORKFLOW_CREATE",
  WORKFLOW_VIEW: "WORKFLOW_VIEW",
  WORKFLOW_EDIT: "WORKFLOW_EDIT",
  WORKFLOW_DELETE: "WORKFLOW_DELETE",
  WORKFLOW_SUBMIT: "WORKFLOW_SUBMIT",
  WORKFLOW_REJECT: "WORKFLOW_REJECT",
  WORKFLOW_APPROVE: "WORKFLOW_APPROVE",
  TEMPLATE_CREATE: "TEMPLATE_CREATE",
  TEMPLATE_VIEW: "TEMPLATE_VIEW",
  TEMPLATE_EDIT: "TEMPLATE_EDIT",
  TEMPLATE_DELETE: "TEMPLATE_DELETE",
  TEMPLATE_SUBMIT: "TEMPLATE_SUBMIT",
  TEMPLATE_REJECT: "TEMPLATE_REJECT",
  TEMPLATE_APPROVE: "TEMPLATE_APPROVE",
  TEMPLATE_CHANGE_TYPE: "TEMPLATE_CHANGE_TYPE",
  TYPE_CREATE: "TYPE_CREATE",
  TYPE_VIEW: "TYPE_VIEW",
  TYPE_EDIT: "TYPE_EDIT",
  TYPE_DELETE: "TYPE_DELETE",
  TYPE_ASSIGN: "TYPE_ASSIGN",
  CONNECTION_CREATE: "CONNECTION_CREATE",
  CONNECTION_VIEW: "CONNECTION_VIEW",
  CONNECTION_EDIT: "CONNECTION_EDIT",
  CONNECTION_DELETE: "CONNECTION_DELETE",
  INPUT_MAPPING_CREATE: "INPUT_MAPPING_CREATE",
  INPUT_MAPPING_VIEW: "INPUT_MAPPING_VIEW",
  INPUT_MAPPING_EDIT: "INPUT_MAPPING_EDIT",
  INPUT_MAPPING_DELETE: "INPUT_MAPPING_DELETE",
  OUTPUT_MAPPING_CREATE: "OUTPUT_MAPPING_CREATE",
  OUTPUT_MAPPING_VIEW: "OUTPUT_MAPPING_VIEW",
  OUTPUT_MAPPING_EDIT: "OUTPUT_MAPPING_EDIT",
  OUTPUT_MAPPING_DELETE: "OUTPUT_MAPPING_DELETE",
  OUTPUT_MAPPING_SHARE: "OUTPUT_MAPPING_SHARE",
  DASHBOARD_VIEW: "DASHBOARD_VIEW",
  USER_EXPORT: "USER_EXPORT",
  USER_GRANT_ADMIN: "USER_GRANT_ADMIN",
  USER_RETRACT_ADMIN: "USER_RETRACT_ADMIN",
  USER_DELETE_ADMIN: "USER_DELETE_ADMIN",
  LOOKUP_CREATE: "LOOKUP_CREATE",
  LOOKUP_VIEW: "LOOKUP_VIEW",
  LOOKUP_DELETE: "LOOKUP_DELETE",
  LOOKUP_APPROVE: "LOOKUP_APPROVE",
  LOOKUP_REJECT: "LOOKUP_REJECT",
  LOOKUP_EDIT: "LOOKUP_EDIT",
  USER_UNBLOCK: "USER_UNBLOCK",
  USER_RESET_MFA: "USER_RESET_MFA",
});

const ROUTES = Object.freeze({
  HOME: "/",
  WORKFLOWS: "/workflows",
  IMPORTS: "/imports",
  SFTP_IMPORT: "imports/sftp",
  EMAIL_IMPORT: "imports/email",
  LOOKUPS: "/lookups",
  TEMPLATES: "/templates",
  OUTPUT_MAPPINGS: "/mappings",
  MATCHING_RULES: "/rules",
  RECONCILIATIONS: "/reconciliations",
  FUNDS: "/fund",
  SUB_ACCOUNTS: "/subAccount",
  REC_TYPES: "/recTypes",
  REC_TYPE_DASH: "/recTypeDash",
  REC_TYPE_CONFIG_DASH: "/recTypeConfigDash",
  UPLOADS: "/uploads",
  GLOBAL_MAPPINGS: "/globalMappings",
});

const ROUTES_FULLSCREEN_MODAL = Object.freeze({
  WORKFLOWS: "/workflows/:workflowUuid/configure",
  TEMPLATES_CREATE: "/templates/create",
  TEMPLATE: "/templates/:templateUuid",
  OUTPUT_MAPPINGS_CREATE: "/mappings/create",
  OUTPUT_MAPPINGS_EDIT: "/mappings/edit/:outputMappingUuid",
  OUTPUT_MAPPINGS_VIEW: "/mappings/view/:outputMappingUuid",
  // Note: Lookups uses standard modal, not pseudo fullscreen modal
});

const ROUTES_NAMES = Object.freeze({
  HOME: "Workflows run results",
  WORKFLOWS: "Workflows",
  IMPORTS: "Imports",
  LOOKUPS: "Lookups",
  TEMPLATES: "Templates",
  HELP: "Help",
  LAUNCH_ADMIN: "Launch admin",
  OUTPUT_MAPPINGS: "Output mappings",
  MATCHING_RULES: "Matching rules",
  RECONCILIATIONS: "Reconciliations",
  FUNDS: "Funds",
  SUB_ACCOUNTS: "Sub accounts",
  DASHBOARDS: "Dashboards",
  REPORTING: "Reporting",
  SUPPORT: "Support",
  UPLOADS: "Uploads",
  GLOBAL_MAPPINGS: "Global mappings",
});

const LOADING_STATES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
});

const FE_SOURCE_TYPES = Object.freeze({
  FUSION_UI: "File upload",
  SFTP: "SFTP",
  EMAIL: "EMAIL",
  DTCC: "DTCC",
});

const BE_SOURCE_TYPES = Object.freeze({
  FUSION_UI: "FUSION_UI",
  SFTP: "SFTP",
  EMAIL: "EMAIL",
  DTCC: "DTCC",
});

const MIME_TYPE = Object.freeze({
  CSV: "text/plain;charset=UTF-8",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  XML: "application/xml",
  DEFAULT: "application/octet-stream",
});

const RULE_TYPE = Object.freeze({
  MATCH_ALL: "MATCH_ALL",
  OR: "OR",
});

const FILE_SIZE = (() => {
  const MAX_MEGABYTES = 15;

  return Object.freeze({
    MAX_MEGABYTES,
    MAX_BYTES: MAX_MEGABYTES * 1024 * 1024,
    MAX_WARNING: `File exceeds maximum upload size of ${MAX_MEGABYTES} MB`,
  });
})();

const TABS = {
  RESULTS: { key: "results", name: "Results" },
  QUEUE: { key: "queue", name: "Queue" },
  OVERVIEW: { key: "overview", name: "Overview" },
  AUDIT_LOG: { key: "audit-log", name: "Audit log" },
  UPLOADED_FILES: { key: "uploaded-files", name: "Uploads" },
};

export {
  AUTH_ROUTES,
  AUTHORITIES,
  ROUTES,
  ROUTES_FULLSCREEN_MODAL,
  ROUTES_NAMES,
  LOADING_STATES,
  MIME_TYPE,
  FE_SOURCE_TYPES,
  BE_SOURCE_TYPES,
  RULE_TYPE,
  FILE_SIZE,
  TABS,
};
